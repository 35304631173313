<template>
    <div class="main-content">
      <div class="layout-px-spacing">
        <div class="layout-top-spacing">
          <div class="layout-spacing">
            <div class="mb-3">
              <h5>Doctor Exemplary Services Reviews</h5>
            </div>
  
            <div class="widget-content widget-content-area">
  
              <b-table
                :items="exemplary"
                :fields="exemplaryServiceFields.filter((field) => field.show === true)"
                :busy="TableBusy"
                @sort-changed="sortingChanged"
                head-variant="light"
                responsive
                bordered
                hover
                show-empty
                no-local-sorting
              >
                <template #table-caption>
                  <i class="fa fa-info-circle"></i>
                  All doctor exemplary services
                </template>
  
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
  
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(patient)="data">
                  {{ data.value.firstname + ' ' + data.value.lastname }}
                </template>

                <template #cell(patient_phone)="data">
                  {{ data.value.phone}}
                </template>

                <template #cell(doctor)="data">
                  {{ data.value.firstname + ' ' + data.value.lastname }}
                </template>
                <template #cell(point)="data">
                  {{ data.value ? data.value : 'NA'}}
                </template>
                <template #cell(created_at)="data">
                    {{ formatFancyDate(data.value) }}
                </template>
              </b-table>
  
              <div class="d-md-flex justify-content-between">
                <span>Total: {{ exemplaryServices ? exemplaryServices.data.total : 0 }}</span>
  
                <b-pagination-nav
                  v-if="exemplaryServices && exemplaryServices.data"
                  :link-gen="linkGen"
                  :number-of-pages="exemplaryServices.data.last_page"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                  limit="10"
                  align="right"
                  last-number
                  use-router
                ></b-pagination-nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { geroendpoints } from "@/utils/endpoints";
  import { gerohttp } from "@/utils/gerocarehttp";
  import pagination from "@/mixins/pagination";
  import "@/assets/js/printThis";
  
  export default {
    name: "ExemplaryServices",
    mixins: [pagination],
    data() {
      return {
        exemplaryServiceFields: [
          {
            key: "index",
            label: "S/N",
            sortable: false,
            show: true,
          },
          {
            key: "patient",
            label: "Patient",
            sortable: true,
            show: true,
          },
         
          {
            key: "patient.phone",
            label: "Patient Phone",
            sortable: false,
            show: true,
          },
          {
            key: "patient.user.name",
            label: "Sponsor",
            sortable: true,
            show: true,
          },
          {
            key: "patient.user.phone",
            label: "Sponsor Phone",
            sortable: false,
            show: true,
          },
          {
            key: "doctor",
            label: "Doctor",
            sortable: true,
            show: true,
          },
          {
            key: "doctor",
            label: "Doctor Phone",
            sortable: false,
            show: false,
          },
          {
            key: "point",
            label: "Review Points",
            sortable: true,
            show: true,
          },
          {
            key: "review",
            label: "Review",
            sortable: true,
            show: true,
          },
          {
            key: "created_at",
            label: "Created",
            sortable: true,
            show: true,
          },
         
        ],
        exemplaryServices: null,
        TableBusy: false,
        query: "",
       
      };
    },
   
    methods: {
      fetchAll() {
        this.patientsTableBusy = true;
  
        gerohttp
          .get(geroendpoints.FETCH_EXEMPLARY_SERVICE + `?${this.query}`)
          .then((response) => {
            this.exemplaryServices = response;
          })
          .catch((error) => {
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          })
          .finally(() => {
            this.patientsTableBusy = false;
          });
      },
      
      sortingChanged(context) {
        let { sortBy, sortDesc } = context;
  
        let sortValue = sortBy;
  
        switch (sortValue) {
          case "sponsor":
            sortValue = "sponsor_name";
            break;
  
          case "doctor":
            sortValue = "doctor_name";
            break;
  
          default:
            break;
        }
  
        sortValue = (sortDesc ? "-" : "") + sortValue;
  
        let queryParams = new URLSearchParams(this.query);
        if (queryParams.has("sort")) {
          this.query = this.query.replace(queryParams.get("sort"), sortValue);
        } else {
          this.query += "&sort=" + sortValue;
        }
  
        this.fetchAll();
      }
    },
    computed: {
    exemplary() {
      return this.exemplaryServices?.data.data || [];
    },
  },
  };
  </script>
  